@import "~antd/dist/antd.css";

.myTab {
  text-align: center;
  padding: 15px 0;
  color: #888;
  border-bottom: 2px solid #888;
  background-color: white;
  cursor: pointer;
}

.myTab:hover {
  background: #ddd;
}

.activeTab {
  background: #ddd;
}

.myTab:active {
  background: #ddd;
}

.absolutelyOrange {
  background-color: orange !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* All the Actions Menus are handcoded. We remove the antd styling for them */
.ant-popover-inner-content {
  padding: 0 !important;
}

#unmergeId .ant-popover-inner-content {
  padding: 12px 16px !important;
}

#archiveEvent .ant-popover-inner-content {
  padding: 12px 16px !important;
}

.green {
  background-color: #e0f2f1 !important;
}

.green > td {
  background-color: #e0f2f1 !important;
}

.grey {
  background-color: #f5f5f5 !important;
}

.grey > td {
  background-color: #f5f5f5 !important;
}
