html,
body {
  background: #ecedf0;
  width: 100%;
}

html {
  font-size: 10px;
}

body {
  color: #777;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 22px;
  margin: 0;
  font-size: 13px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.pull-left {
  float: left;
}

a:hover,
a:focus {
  color: #d9d9d9;
}

a:active {
  color: #bfbfbf;
}

/* Layout Base - Content Body */
.content-body {
  display: table-cell;
  padding: 40px;
  position: relative;
  vertical-align: top;
}

.content-body > .row + .row {
  padding-top: 10px;
}


/* Trace moved to GlobalStyles.tsx */
/* Layout Base - Page Header */
/* .page-header {
  background: #25bfea;
  border-bottom: none;
  border-left: 1px solid #3a3a3a;
  box-shadow: 1px 3px 0 1px #cccccc;
  height: 50px;
  margin: -40px -40px 40px -40px;
  padding: 0;
} */

/* Trace moved to GlobalStyles.tsx */
/* Layout Base - Sidebar Left */
/* .sidebar-left {
  background: #25bfea;
  color: white !important;
  display: table-cell;
  position: relative;
  vertical-align: top;
  width: 300px;
  z-index: 1010;
} */
.ui-sortable-helper {
  z-index: 999999 !important;
}

@media only screen and (max-width: 767px) {
  html,
  body {
    background: #ecedf0;
  }

  body {
    min-height: 100vh;
  }

  .header {
    background: none;
    border: none;
    height: auto;
    position: static;
  }

  .content-body {
    padding: 0 15px 15px;
  }

  /* .page-header {
    margin: 0 -15px 20px;
  } */
}

@media screen and (max-width: 991px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td {
    border-bottom: 0;
  }
}

/* .sidebar-left {
  z-index: 1010;
} */

ul.nav-main > li > a {
  padding: 12px 25px;
}

ul.nav-main > li > a:hover,
ul.nav-main > li > a:focus {
  background-color: #037994;
}

ul.nav-main > li.nav-expanded > a {
  /*background: #037994;*/
}

ul.nav-main li a {
  font-size: 13px;
  font-size: 1.3rem;
  /*color: #abb4be;*/
  color: white;
  white-space: nowrap;
  /*text-overflow: ellipsis;*/
}

ul.nav-main li a span.label {
  font-weight: normal;
  font-size: 10px;
  font-size: 1rem;
  padding: 0.3em 0.7em 0.4em;
  margin: 0.4em -1em 0 0;
}

ul.nav-main li span {
  vertical-align: middle;
}

ul.nav-main li i {
  font-size: 18px;
  font-size: 1.8rem;
  width: 1.4em;
  margin-right: 1.1em;
  text-align: center;
  vertical-align: middle;
}

ul.nav-main li.nav-parent {
  position: relative;
}

ul.nav-main li.nav-parent > a {
  cursor: pointer;
}

ul.nav-main li.nav-parent > a:after {
  font-family: "FontAwesome";
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  /*color: #abb4be;*/
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 25px;
}

ul.nav-main li.nav-parent.nav-expanded > a:after {
}

ul.nav-main li.nav-parent.nav-expanded > ul.nav-children {
  display: block;
}

ul.nav-main li .nav-children {
  background: #25bfea;
  /*box-shadow: 0px -3px 3px -3px rgba(0, 0, 0, 0.7) inset;*/
  display: none;
  padding: 10px 0;
}

ul.nav-main li .nav-children li a {
  padding: 6px 15px 6px 57px;
  overflow: hidden;
}

ul.nav-main li .nav-children li a:hover,
ul.nav-main li .nav-children li a:focus,
ul.nav-main li.active a {
  background: #037994;
}

ul.nav-main li .nav-children li a:after {
  padding: 6px 25px;
}

ul.nav-main li .nav-children .nav-children {
  box-shadow: none;
  padding: 0;
}

ul.nav-main li .nav-children .nav-children li a {
  padding: 6px 15px 6px 82px;
}

ul.nav-main li .nav-children .nav-children .nav-children li a {
  padding: 6px 15px 6px 97px;
}

/* Page Header */
/* .page-header {
  z-index: 1001;
} */

/* .page-header h2 {
  color: #fff;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: inline-block;
  float: left;
  height: 50px;
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: normal;
  line-height: 50px;
  margin: 0 0 0 -1px;
  padding: 0 22px 0 20px;
} */

/* Page Header - Mobile */
/* @media only screen and (max-width: 767px) {
  .page-header {
    padding-right: 80px;
  }

  .page-header h2 {
    font-size: 16px;
    padding: 0 15px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
} */
/* Headings */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  letter-spacing: -1px;
}

h1,
.h1 {
  font-size: 36px;
  font-size: 3.6rem;
}

h2,
.h2 {
  font-size: 30px;
  font-size: 3rem;
}

h3,
.h3 {
  font-size: 24px;
  font-size: 2.4rem;
}

h4,
.h4 {
  font-size: 18px;
  font-size: 1.8rem;
}

/* Hightlight */
.highlight {
  background-color: #cccccc;
  color: #fff;
  padding: 3px 6px;
}

input {
  outline: none;
}

label {
  font-weight: normal;
}

select {
  border: 1px solid #e5e7e9;
  border-radius: 6px;
  height: 46px;
  padding: 12px;
  outline: none;
}

/* Forms Validations */
label.valid {
  display: inline-block;
  text-indent: -9999px;
}

label.error {
  color: #c10000;
  font-size: 0.9em;
  margin-top: -5px;
  padding: 0;
}

/* Miscellaneous */
body a,
body a:focus,
body a:hover,
body a:active,
body a:visited {
  outline: none !important;
}

.center {
  text-align: center;
}

ul {
  margin-bottom: 0;
  padding-left: 27px;
}

.text-muted {
  color: #999999 !important;
}

/* weights */

.text-weight-bold {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

/* spacement top  */

.mt-md {
  margin-top: 15px !important;
}

.mt-lg {
  margin-top: 20px !important;
}

/* spacement bottom */
.mb-none {
  margin-bottom: 0 !important;
}

.mb-md {
  margin-bottom: 15px !important;
}

.mb-lg {
  margin-bottom: 20px !important;
}

/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  -webkit-appearance: none;
}

.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder {
  color: #bdbdbd;
}

.form-control::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="color"]::-moz-placeholder {
  color: #bdbdbd;
}

.form-control:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder {
  color: #bdbdbd;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

/* Form - Bootstrap Override */

select.input-lg {
  line-height: 1;
}

/* Form - Custom Fields */
.required {
  display: inline-block;
  color: #d2322d;
  font-size: 0.8em;
  font-weight: bold;
  position: relative;
  top: -0.2em;
}

label.error {
  color: #b94a48;
  margin-top: 2px;
}

/* Form - Group Override */
.form-group:after {
  clear: both;
  display: block;
  content: "";
}

.form-group:last-child,
.form-group:last-of-type {
  margin-bottom: 0;
}

.input-lg {
  border-radius: 4px;
}

.panel {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  /* WHY?
  + .panel {
    margin-top: 35px;
  }*/
}

.panel-heading {
  background: #f6f6f6;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #dadada;
  padding: 18px;
  position: relative;
}

.panel-title {
  color: #33353f;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  text-transform: none;
}

.panel-body {
  background: #fdfdfd;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.ourpanel-body {
  background: #fdfdfd;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
}

.display {
  display: flex;
}

.panel-heading + .ourpanel-body {
  border-radius: 0 0 5px 5px;
}

.panel-heading + .panel-body {
  border-radius: 0 0 5px 5px;
}

.panel-footer {
  border-radius: 0 0 5px 5px;
  margin-top: -5px;
}

.chart {
  width: 100%;
}

/* Tables - Basic */
.table {
  width: 100%;
}

.table .table {
  background: transparent;
}

/* Bootstrap uses important, we need to force it here */
.table.mb-none {
  margin-bottom: 0 !important;
}

/* In case you dont want a border in some row */

/* Tables - Actions */
.table .actions {
  vertical-align: middle;
}

.table .actions a {
  display: inline-block;
  margin-right: 5px;
  color: #666;
}

.table .actions a:last-child {
  margin-right: 0;
}

.table .actions a:hover {
  color: #333;
}

/* Tables - No More Tables technique (991px is the bootstrap SM max-width) */
@media only screen and (max-width: 991px) {
  .table.table-no-more,
  .table.table-no-more thead,
  .table.table-no-more tbody,
  .table.table-no-more tr,
  .table.table-no-more th,
  .table.table-no-more td {
    display: block;
  }

  .table.table-no-more thead tr {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }

  .table.table-no-more tr {
    border-bottom: 1px solid #ddd;
  }

  .table.table-no-more td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
    white-space: normal;
  }

  .table.table-no-more td:before {
    content: attr(data-title);
    font-weight: bold;
    left: 6px;
    padding-right: 10px;
    position: absolute;
    text-align: left;
    top: 8px;
    white-space: nowrap;
    width: 45%;
  }

  .table.table-no-more.table-bordered td {
    border-bottom: 1px solid #efefef;
  }
}

.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th {
  color: #fff;
  background-color: #cccccc;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th {
  color: #fff;
  background-color: #47a447;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th {
  color: #fff;
  background-color: #ed9c28;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th {
  color: #fff;
  background-color: #d2322d;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th {
  color: #fff;
  background-color: #5bc0de;
}

#checkerTable {
  border: 1px solid#ddd;
  color: rgb(117, 117, 117);
  padding: 15px;
  font-size: 13px;
  width: 100%;
  padding: 8px;
  border-collapse: collapse;
}



#checkerTable td {
  border: 1px solid#ddd;

}

.background-red th {
  border: 1px solid#ddd;
  padding: 8px;
  vertical-align: bottom;
  border-bottom-width: 2px;
  line-height: 1.42857143
}
#checkerTable > tbody > tr:nth-child(even) > td {
  background-color: #fff;
  padding: 8px;
  line-height: 1.42857143;
}
#checkerTable > tbody > tr:nth-child(odd) > td {
  background-color: #f9f9f9;
  padding: 8px;
  line-height: 1.42857143;
}

#horizontal {

    border-bottom: 1px solid #ddd;
    padding: 8px;
    line-height: 2.4

}

#horizontalHeader th{
border-bottom: 2px solid #ddd;
padding: 8px;
line-height: 2.4
}



.switch {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 3px 0;
}

.field-validation-error {
  color: #b94a48;
}

.smspace {
  padding-bottom: 4px;
}

.marg-t20 {
  margin-top: 20px !important;
}

#prev,
#next {
  background: rgb(153, 153, 153) none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  padding: 4px 8px;
  cursor: pointer;
}

h2.swimmeet-title + .row {
  clear: both;
  overflow: hidden;
  position: relative;
}

h2.swimmeet-title + .row::before {
  border-left: 1px solid #ccc;
  content: "";
  display: block;
  height: 100%;
  left: 42.3%;
  position: absolute;
  width: 1px;
}
h2.swimmeet-title + .row::after {
  border-left: 1px solid #ccc;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 24.3%;
  width: 1px;
}
.swimmeet-title {
  /* border-bottom: 1px solid #ccc; */
  border-top: 1px solid #ccc;
  margin-right: 0px;
  margin-bottom: 5px;
}

.selectsec select {
  border: 1px solid #ccc;
  border-radius: 0;
  margin: 0 !important;
  padding: 2px !important;
  width: 100%;
}

a.sidebar-add-swimmer-btn {
  background-color: #25bfea;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-right: 9px;
  text-align: center;
  width: 20px;
  cursor: pointer;
}

span.sidebar-add-swimmer-btn {
  background-color: #25bfea;
  border-radius: 3px;
  color: white;
  display: inline-block;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-right: 9px;
  text-align: center;
  width: 20px;
  cursor: pointer;
}

#tblLanes tr td select,
#tblLanes tr td input[type="text"],
#divmeet tr td select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
#tblLanes tr td select:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

#tblLanes tr td {
  padding: 10px 0;
}

#divmeet tr td {
  position: relative;
}
#divmeet tr td select {
  display: inline-block;
  margin-top: 10px;
}
#divmeet tr td select + a {
  position: absolute;
  right: -17px;
  top: 13px;
  z-index: 999999;
}

#HomeClubImg img {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 4px;
}

#tblLanes tr td .row {
  margin: 0;
  padding: 0;
}
#tblLanes tr td .row > div {
  margin: 0;
  padding: 0;
}

#MeetClubLanes input[type="radio"],
#MeetClubLanes input[type="checkbox"] {
  display: none;
}

#MeetClubLanes input[type="radio"] + label,
#MeetClubLanes input[type="checkbox"] + label {
  display: inline-block;
  margin: -2px;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#fff),
    to(#e6e6e6)
  );
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #ccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#ffffffff",
      endColorstr="#ffe6e6e6",
      GradientType=0
    );
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}

#divMeetlines {
  padding: 10px 0 20px;
}

#MeetClubLanes input[type="radio"]:checked + label,
#MeetClubLanes input[type="checkbox"]:checked + label {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #25bfea;
}

.ourpanel-body.marg-t20.bornone {
  border: 0 none;
  box-shadow: none;
}

/* .page-header {
  z-index: 8;
}
.sidebar-left {
  z-index: 9;
} */

.first.current {
  text-align: center;
  font-size: 15px;
}
.next.current {
  text-align: center;
  font-size: 15px;
}

.event-lineup-header {
  background-color: #ddd;
}

.swimmernav p {
  display: inline-block;
  float: left;
  margin: 0;
  padding: 4px;
}

.swimmernav p.event-lineup-header-age {
  width: 18%;
}

#sidebar ul {
  list-style: outside none none;
}
#sidebar ul li {
  padding-bottom: 3px;
  padding-top: 3px;
}

#sidebar h3 + #content {
  margin: 0 !important;
  padding: 0 4px;
}

.swimmernav p.event-lineup-header-swimmer {
  width: 44%;
}

th.headName {
  width: 14%;
}

th.headClub {
  width: 25%;
}

th.headEventPlace {
  width: 14%;
}

th.headLane {
  width: 7.5%;
}

th.headPoints {
  width: 8%;
}

a.remove-from-event {
  display: none;
  float: right;
}
a.remove-from-event {
  display: none;
  float: right;
}

div.event-lineup-row:hover a.remove-from-event {
  display: inline-block;
}

body ui-dialog .close {
  display: none;
}

.swimmeet-title {
  color: #0088cc;
}

#results {
  font-size: 23px;
  margin: 0 0 16px;
  padding: 0;
}

@media only screen and (min-width: 1199px) {
  .table-responsive {
    overflow-x: visible;
  }
}

@media only screen and (max-width: 991px) {
  h2.swimmeet-title + .row::after {
    display: none;
  }
  h2.swimmeet-title + .row::before {
    left: 58.3%;
  }
  .swimmernav p.event-lineup-header-swimmer {
    width: 41%;
  }

  .sidebar span a {
    font-size: 11px;
  }

  .col4borderRL #sidebar div div span {
    max-width: 170px;
  }

  .table-responsive {
    border: 0 none;
  }

  .table-responsive {
    border: 0 none;
    padding-bottom: 10px;
  }

  .league {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .swimmernav p.event-lineup-header-age {
    width: 15%;
  }
}

ul.nav-main li.nav-parent > a:first-child {
}
ul.nav-main li.nav-parent > ul {
  display: block;
}

a {
  cursor: pointer;
}

.selectsec select {
  height: 26px;
}

.removeSwimmerFromMeet {
  cursor: pointer;
  display: none;
  float: right;
  font-size: 20px;
  padding: 0 5px;
}

#sidebar ul li:hover .removeSwimmerFromMeet {
  cursor: pointer;
  display: block;
}

.disabled {
  background-color: #ddd !important;
}

.strike {
  color: #ddd;
  text-decoration: line-through;
}
.first_name {
  float: left;
  margin-right: 5px;
}

p.event-lineup-header-swimmer,
div.event-lineup-row p.swimmer-name {
  width: 54%;
}
p.event-lineup-header-age,
div.event-lineup-row p.swimmer-age {
  width: 10%;
}

#lane_error {
  color: #b94a48;
}

.shifted li a {
  padding-left: 23px !important;
}

.my_dragged_element {
  border: 1px solid #000 !important;
  background: #25bfea !important;
  color: #fff !important;
}

.bg-change {
  background-color: #037994 !important;
  color: #fff !important;
}

.heading-div {
  width: 70%;
  display: inline-block !important;
}
